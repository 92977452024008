<template>
  <TTView v-if="newLicenses.length">
    <VRow>
      <VCol class="d-flex justify-center pt-0">
        <div class="license-create-container">
          <h1 class="tt-text-headline-1">
            Выдача лицензии
          </h1>
          <VExpansionPanels
            v-model="panel"
            class="py-3 panel"
          >
            <VExpansionPanel>
              <VExpansionPanelHeader
                expand-icon="fal fa-chevron-down"
              >
                <h2 class="font-weight-bold">
                  Автозаполнение лицензий
                </h2>
              </VExpansionPanelHeader>
              <VExpansionPanelContent>
                <CompanyLicenseCreateForm
                  key="template-form"
                  :licence="templateLicense"
                  :total-licence="0"
                  :all-app-licence="[]"
                  :account-id="accountId"
                  :company-id="companyId"
                  :can-delete="false"
                  :show-title="false"
                  template-form
                  @change="updateLicenceTemplate"
                  @change-extra="templateLicenseExtra = $event"
                />

                <TTBtn
                  class="mt-6 mb-4"
                  @click="fillAllLicenses"
                >
                  Заполнить все формы по шаблону
                </TTBtn>
              </VExpansionPanelContent>
            </VExpansionPanel>
          </VExpansionPanels>
          <div :key="`licensesFormsKey_${licensesFormsKey}`">
            <CompanyLicenseCreateForm
              v-for="(licence, i) in newLicenses"
              :key="licence.appName"
              class="license-create-form"
              :licence="licence"
              :template-extra-data="templateLicenseExtra"
              :total-licence="totalLicence(licence.appName)"
              :all-app-licence="allAppLicence(licence.appName)"
              :account-id="accountId"
              :company-id="companyId"
              @change="updateLicence($event, i)"
              @delete="deleteLicence($event, i)"
            />
          </div>
          <div class="license-create-buttons d-flex mt-8">
            <TTBtn
              :to="{ name : Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE }"
              color="tt-light-mono-8 mr-5"
            >
              Отменить
            </TTBtn>
            <TTBtn
              :disabled="isAllFormsNotValid"
              @click="createAllLicenses"
            >
              Выдать лицензии
            </TTBtn>
          </div>
        </div>
      </VCol>
    </VRow>
    <template #right>
      <VRow>
        <VCol>
          <div class="license-create-close d-flex flex-column align-center absolute">
            <TTBtn
              :to="{ name : Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE }"
              class="license-create-close-btn"
              color="tt-light-mono-100"
              icon
            >
              <VIcon>fal fa-times</VIcon>
            </TTBtn>
            <span class="tt-text-footnote tt-light-mono-46--text mt-2">
              Закрыть
            </span>
          </div>
        </VCol>
      </VRow>
    </template>
  </TTView>
</template>

<script>
import CompanyLicenseCreateForm from '../../../components/v2/CompanyLicenseCreateForm.vue';

export default {
  name: 'CompanyLicenseCreate',
  components: { CompanyLicenseCreateForm },
  inject: ['Names'],
  props: {
    accountId: {
      required: true,
      type: String,
    },
    companyId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      licenses: [],
      newLicenses: [],
      templateLicense: {
        appName: '',
        isValid: false,
        licensee: { companyId: this.companyId },
        duration: {
          startsAt: '',
          days: null,
        },
        totalUsers: null,
        involvedUsers: 0,
        active: true,
      },
      templateLicenseExtra: null,
      panel: undefined,
      licensesFormsKey: 0,
    };
  },
  computed: {
    queryApps() {
      return this.$route.query.apps;
    },
    isLastQuery() {
      return this.queryApps.length === 1 || typeof this.queryApps === 'string';
    },
    isAllFormsNotValid() {
      return !!this.newLicenses.find((el) => !el.isValid);
    },
  },
  async created() {
    this.createNewLicenses();
    await this.getLicense();
  },
  methods: {
    async getLicense() {
      try {
        const licenseApp = new this.$di.ddd.License();
        const { accountId, companyId } = this;
        const [licenses] = await licenseApp.services.license.getByCompanyOrAccount({
          accountId,
          companyId,
        });

        this.licenses = licenses;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    async createAllLicenses() {
      try {
        const licenseApp = new this.$di.ddd.License();
        const dto = { licenceList: this.newLicenses };
        await licenseApp.services.license.createLicense(dto);

        await this.$router.push({ name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    fillAllLicenses() {
      this.licensesFormsKey += 1;
      this.createNewLicenses({ ...this.templateLicense });
    },
    updateLicenceTemplate(licence) {
      this.templateLicense = {
        ...this.templateLicense,
        licensee: licence.licensee,
        duration: { ...licence.duration },
        totalUsers: licence.totalUsers,
        isValid: licence.isValid,
      };
    },
    updateLicence(licence, index) {
      this.newLicenses[index].licensee = licence.licensee;
      this.newLicenses[index].duration = licence.duration;
      this.newLicenses[index].totalUsers = licence.totalUsers;
      this.newLicenses[index].isValid = licence.isValid;
    },
    deleteLicence(licence, index) {
      if (this.isLastQuery) {
        this.$router.push({ name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE });
        return;
      }

      const newApps = this.queryApps.slice();
      newApps.splice(index, 1);

      this.$router.push({
        query: {
          ...this.$route.query,
          apps: newApps,
        },
      });

      this.newLicenses.splice(index, 1);
    },
    totalLicence(appName) {
      let licenceCounter = 0;
      this.licenses.forEach((el) => {
        if (el.appName === appName) licenceCounter += 1;
      });

      return licenceCounter;
    },
    allAppLicence(appName) {
      const allAppLicence = this.licenses.filter((el) => el.appName === appName);

      return allAppLicence.map((el) => ({
        type: el.licensee.companyId ? 'company' : 'account',
        from: this.$dayjs(el.duration.startsAt)
          .format('DD.MM.YYYY'),
        to: this.$dayjs(el.duration.startsAt)
          .add(el.duration.days, 'day')
          .format('DD.MM.YYYY'),
        modifiedFrom: this.$dayjs(el.duration.startsAt).valueOf(),
        modifiedTo: this.$dayjs(el.duration.startsAt)
          .add(el.duration.days, 'day')
          .valueOf(),
      }));
    },
    createNewLicenses(template = {}) {
      let appNames = [];

      if (typeof this.queryApps === 'string') {
        appNames.push(this.queryApps);
      } else {
        appNames = [...this.queryApps];
      }

      this.newLicenses = appNames.map((el) => ({
        appName: el,
        isValid: false,
        licensee: template?.licensee || {
          companyId: '',
          accountId: '',
        },
        duration: template?.duration || {
          startsAt: '',
          days: null,
        },
        totalUsers: template?.totalUsers || null,
        involvedUsers: 0,
        active: true,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
  .license-create-container {
    width: 1128px;
  }
  .license-create-form::after {
    content: '';
    margin-top: 24px;
    display: block;
    height: 1px;
    width: 1128px;
    background: map-get($tt-light-mono-8, 'base');
  }
  .license-create-form {
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  .license-create-close {
    width: 44px;
    position: absolute;
    top: 25px;
    right: 64px;
  }
  .license-create-close-btn {
    box-shadow: 0 2px 16px rgba(11, 18, 24, 0.12) !important;
  }
  .panel {
    width: 795px;
  }
</style>
